body {
    display: flex;
    min-height: 100vh;
    flex-direction: column; /* specify vertical direction */
}

#brandClock {
    margin-left: .5em;
}

#brandItem {
    font-weight: bold;
    font-size: 1.5rem;
}

main {
    flex: 1; /* main should take up all the space it can to push footer to bottom of page */
}

#app {
    display: flex;
}

.timer-card {
    flex: 1;
    height: 250px;
    min-width: 320px;
    max-width: 320px;
    padding: 15px;
}

.edit-input-control {
    width: 82%;
    padding: 8px 0 0 10px;
}

#timer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

@media screen and (max-width: 639px) {
    #timer-container {
        justify-content: center;
    }
}

.name-content {
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.card-header-title {
    overflow: hidden;
}

.name-content:hover {
    color: darkslategrey;
}

.play-button {
    font-size: 2.3rem;
    cursor: pointer;
    color: #3ec46d;
    margin: 0 .5rem;
}

.play-button:hover {
    color: #3edf7d;
}

.reset-button {
    font-size: 2rem;
    cursor: pointer;
    color: #3273DC;
    margin: 0 .5rem;
}

.reset-button:hover {
    color: #3273FF
}

.action-row {
    margin: 1rem 0;
    padding: 0 15px;
}

.hide {
    display: none !important;
}

.delete-button {
    position: absolute;
    right: 6px;
    top: 5px;
    color: #ff4b4b;
    font-size: 1.1rem;
    cursor: pointer;
}

.delete-button:hover {
    color: #fc2666;
}

.delete-background {
    position: absolute;
    right: -7px;
    top:-7px;
    color: #fff;
    font-size: 1.4rem;
    z-index: 10;
    cursor: pointer;
}

.timer-click {
    cursor: pointer;
    font-family: ui-monospace, Consolas, Menlo, monospace;
}

.timer-click:hover {
    color: darkslategrey;
}

.timer-edit {
    max-width: 22%;
    height: 80%;
    align-self: center;
    flex: 1;
    border-radius: 5px;
    border: 1px solid gray;
    text-align: center;
    font-size: 1.4rem;
    font-family: ui-monospace, Consolas, Menlo, monospace;
}

.timer-edit-container {
    height: 100%;
    display: flex;
    justify-content: space-evenly;
}

.edit-controls {
    justify-content: center;
    display: flex;
    height: 30%;
}

.edit-controls > .button {
    align-self: center;
    margin: 0 .5rem;
}

.card-content {
    text-align: center;
    font-size: 2.3rem;
}

div.card.is-complete {
    border: 1.5px solid red;
}

.dropdown-item {
    cursor: pointer;
}

span.dropdown-item:hover {
    background-color: #f5f5f5;
}
